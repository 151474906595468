import React from "react";

function article_1() {
	return {
		date: "7 Mar 2024",
		title: "The Magic of Recursion: Unraveling Programming's Greatest Mystery",
		description:
			"Delve into the captivating world of recursion, a powerful programming technique that unlocks endless possibilities. Explore its elegant simplicity and discover how it empowers developers to solve complex problems with ease.",
		keywords: [
			"Recursion",
			"Programming",
			"Algorithms",
			"Computer Science",
		],
		style: `
                .article-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .randImage {
                    align-self: center;
                    outline: 2px solid red;
                }
                `,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Recursion, often hailed as the enigmatic force within
						the realm of programming, represents a journey into the
						unknown depths of problem-solving. At its core,
						recursion embodies a profound elegance, enabling
						developers to navigate intricate mazes of complexity
						with unparalleled grace and precision. With each
						recursive call, a new chapter unfolds, revealing the
						infinite possibilities that lie within the labyrinth of
						code. From traversing data structures to deciphering
						mathematical conundrums, recursion serves as a guiding
						beacon, illuminating the path to enlightenment in the
						ever-evolving landscape of computer science.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "1 Jan 2024",
		title: "The Joy of Code Refactoring: Transforming Chaos into Elegance",
		description:
			"Discover the art of code refactoring, a practice that transforms messy, tangled code into elegant, efficient solutions. Explore the principles behind refactoring and learn how it enhances readability, maintainability, and overall code quality.",
		keywords: [
			"Code Refactoring",
			"Programming",
			"Software Development",
			"Clean Code",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Code refactoring, the masterful craft of software
						development, embodies the journey from chaos to clarity,
						from confusion to elegance. It is the art of reshaping
						tangled, convoluted code into a harmonious symphony of
						structure and simplicity. With each refactoring
						endeavor, developers embark on a transformative quest,
						meticulously sculpting raw code into a masterpiece of
						efficiency and readability. Through disciplined
						practices and thoughtful iteration, code refactoring
						breathes new life into stale codebases, infusing them
						with vitality and vigor. Embrace the joy of refactoring
						and witness the metamorphosis of chaos into elegance,
						one line of code at a time.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "15 Aug 2024",
		title: "Exploring JavaScript Closures: A Deep Dive into Scoping and Lexical Environment",
		description:
			"Unpack the concept of closures in JavaScript and understand how they work within the scoping and lexical environment. Learn why closures are fundamental for building modular, maintainable code.",
		keywords: [
			"JavaScript",
			"Closures",
			"Scoping",
			"Lexical Environment",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Closures in JavaScript offer a fascinating look into how
						the language handles variable scope and memory. At its
						core, a closure allows a function to "remember" its
						surrounding environment even after it has finished
						executing. This seemingly simple concept opens up
						complex behavior, enabling patterns like currying and
						modularization. Understanding closures helps developers
						achieve more efficient and maintainable code by
						effectively controlling variable scope and managing
						state. Through examples of closures in action, you'll
						see how this fundamental concept continues to shape
						JavaScript's evolving architecture.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_4() {
	return {
		date: "22 Feb 2024",
		title: "Async/Await in JavaScript: Mastering Asynchronous Programming",
		description:
			"Explore how async/await revolutionized asynchronous programming in JavaScript. Learn how this syntactic sugar simplifies promises and helps you write cleaner, more readable code for complex asynchronous workflows.",
		keywords: [
			"JavaScript",
			"Async/Await",
			"Asynchronous Programming",
			"Promises",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Asynchronous programming can be challenging, but with
						the introduction of async/await in JavaScript, handling
						asynchronous code has never been easier. This powerful
						feature builds on promises, providing a more intuitive
						and readable syntax to handle asynchronous flows.
						Whether you're working with API requests, file I/O, or
						any other task that takes time, async/await helps you
						avoid callback hell and makes error handling
						straightforward. Dive into the world of async/await and
						discover how it transforms asynchronous programming into
						a manageable, elegant part of your development
						workflow.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_5() {
	return {
		date: "9 Jul 2024",
		title: "Mastering JavaScript Promises: From Callbacks to Promise Chaining",
		description:
			"Promises revolutionized asynchronous operations in JavaScript. Learn the evolution from callbacks to promises and understand how chaining promises leads to better, more maintainable code.",
		keywords: [
			"JavaScript",
			"Promises",
			"Asynchronous Programming",
			"Callback Hell",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						JavaScript's promises offer a clean and elegant solution
						to the once-dreaded 'callback hell.' Promises
						introduced a formal approach to handle asynchronous
						operations, allowing developers to chain actions and
						handle errors effectively. With promise chaining,
						complex asynchronous operations can be written in a more
						readable and structured manner. Dive into the world of
						promises, understand how they evolved from traditional
						callback functions, and learn how to use them to handle
						asynchronous events efficiently in modern JavaScript
						applications.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3, article_4, article_5];

export default myArticles;
